import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';

const partners = [
  { id: 1, src: 'assets/img/Partners/1.jpg' },
  { id: 2, src: 'assets/img/Partners/22.jpg' },
  { id: 3, src: 'assets/img/Partners/33.jpg' },
  { id: 4, src: 'assets/img/Partners/44.jpg' },
  { id: 5, src: 'assets/img/Partners/55.jpg' },
  { id: 6, src: 'assets/img/Partners/66.jpg' },
  { id: 7, src: 'assets/img/Partners/88.jpg' },
  { id: 8, src: 'assets/img/Partners/99.jpg' },
  { id: 9, src: 'assets/img/Partners/100.jpg' },
  { id: 10, src: 'assets/img/Partners/111.jpg' },
  { id: 11, src: 'assets/img/Partners/222.jpg' },
  { id: 12, src: 'assets/img/Partners/333.jpg' },
  { id: 13, src: 'assets/img/Partners/444.jpg' },
  { id: 14, src: 'assets/img/Partners/555.jpg' },
  { id: 15, src: 'assets/img/Partners/666.jpg' },
  { id: 16, src: 'assets/img/Partners/777.jpg' },
  { id: 17, src: 'assets/img/Partners/888.jpg' },
  { id: 18, src: 'assets/img/Partners/1000.jpg' },
  { id: 19, src: 'assets/img/Partners/1111.jpg' },
  { id: 20, src: 'assets/img/Partners/2222.jpg' },
  { id: 21, src: 'assets/img/Partners/3333.jpg' },
  { id: 22, src: 'assets/img/Partners/4444.jpg' },
  { id: 23, src: 'assets/img/Partners/5555.jpg' },
  { id: 24, src: 'assets/img/mnm-g.png'}
];

const PartnersSection = () => {
  return (
    <div className="container my-5">
       <div className="section-title">
          <h2>MNM Solution</h2>
          <p>Our Esteemed Partners</p>
        </div>
      <div className="row">
        {partners.map(partner => (
          <div key={partner.id} className="col-6 col-sm-4 col-md-2 mb-4">
            <img src={partner.src} alt={`Partner ${partner.id}`} className="img-fluid" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PartnersSection;
