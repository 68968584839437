import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faClock, faGlobe, faPhone, faEnvelope, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function Fotter() {
  return (
    <footer id="footer">
      <div className="footer-top py-5">
        <div className="container">
          <div className="row">
            {/* Location Section */}
            <div className="col-md-4 mb-4">
              <h1 className="font-weight-bold mb-4" style={{ fontFamily: "Arial, sans-serif", fontSize: "2.5rem" }}>Location</h1>

              <div className="mb-3">
                <div className="d-flex align-items-start">
                  <div className=" bg-danger text-white d-flex align-items-center justify-content-center mx-3" style={{ borderRadius: '50%', width: '30px', height: '30px', marginLeft: '10px' }}>
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="text-white mr-3" size="lg" />
                  </div>
                  <div>
                    <span className="font-weight-bold">Corporate Office</span>
                    <p>
                      1st Floor Thumbali Building, <br />
                      Opp Gayatri School, <br />
                      Near Little Angle School, <br />
                      MVP Sector 3, Vizag - 530017 <br />

                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-start">
                <div className=" bg-danger text-white d-flex align-items-center justify-content-center mx-3" style={{ borderRadius: '50%', width: '30px', height: '30px', marginLeft: '10px' }}>
                  <FontAwesomeIcon icon={faClock} className="text-white mr-3" size="lg" />
                </div>
                <div>
                  <span className="font-weight-bold">Working Hours</span>
                  <p>9:30 a.m. to 6:30 p.m. (Mon – Sat)</p>
                </div>
              </div>
            </div>

            {/* Contact Info Section */}
            <div className="col-md-4 mb-4">
              <h1 className="font-weight-bold mb-4" style={{ fontFamily: "Arial, sans-serif", fontSize: "2.5rem" }}>Contact Info</h1>
              <div className="mb-3">
                <div className="d-flex align-items-start">
                  <div className=" bg-danger text-white d-flex align-items-center justify-content-center mx-3" style={{ borderRadius: '50%', width: '30px', height: '30px', marginLeft: '10px' }}>
                    <FontAwesomeIcon icon={faGlobe} className="text-white mr-3" size="lg" />
                  </div>
                  <div>
                    <a href="mnmsolutions.in" className="font-weight-bold text-white" style={{ textDecoration: 'none' }}>Website</a>
                    <p>mnmsolutions.in</p>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="d-flex align-items-start">
                  <div className=" bg-danger text-white d-flex align-items-center justify-content-center mx-3" style={{ borderRadius: '50%', width: '30px', height: '30px', marginLeft: '10px' }}>
                    <FontAwesomeIcon icon={faPhone} className="text-white mr-3" size="lg" />
                  </div>
                  <div>
                    <span className="font-weight-bold">Call Us</span>
                    <p>9000431447</p>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="d-flex align-items-start">
                  <div className=" bg-danger text-white d-flex align-items-center justify-content-center mx-3" style={{ borderRadius: '50%', width: '30px', height: '30px', marginLeft: '10px' }}>
                    <FontAwesomeIcon icon={faWhatsapp} className="text-white mr-3" size="lg" />
                  </div>
                  <div>
                    <span className="font-weight-bold">WhatsApp</span>
                    <p>9000431447</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex align-items-start">
                  <div className=" bg-danger text-white d-flex align-items-center justify-content-center mx-3" style={{ borderRadius: '50%', width: '30px', height: '30px', marginLeft: '10px' }}>
                    <FontAwesomeIcon icon={faEnvelope} className="text-white mr-3" size="lg" />
                  </div>
                  <div>
                    <span className="font-weight-bold">Email Us</span>
                    <p>
                      support@mnmsolutions.in <br />
                      hr@mnmsolutions.in
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-4">
              <h1 className="font-weight-bold mb-4" style={{ fontFamily: "Arial, sans-serif", fontSize: "2.5rem" }}>Resource Link</h1>
              <ul className="list-unstyled" style={{ padding: '10px 0' }}>
                {/* <li style={{ marginBottom: '10px' }}>
                    <a href="#hero" className="text-white" style={{ fontFamily: "Arial, sans-serif", fontSize: "1.0rem", textDecoration: 'none' }}>Home</a>
                </li> */}
                <li style={{ marginBottom: '10px' }}>
                  <a href="#about" className="text-white" style={{ fontFamily: "Arial, sans-serif", fontSize: "1.0rem", textDecoration: 'none' }}>About MNM Solutions</a>
                </li>
                <li style={{ marginBottom: '10px' }}>
                  <a href="#services" className="text-white" style={{ fontFamily: "Arial, sans-serif", fontSize: "1.0rem", textDecoration: 'none' }}>Services Of MNM Solutions</a>
                </li>
                <li style={{ marginBottom: '10px' }}>
                  <a href="#Departments" className="text-white" style={{ fontFamily: "Arial, sans-serif", fontSize: "1.0rem", textDecoration: 'none' }}> MNM Solutions Team</a>
                </li>
                <li style={{ marginBottom: '10px' }}>
                  <a href="#Careers" className="text-white" style={{ fontFamily: "Arial, sans-serif", fontSize: "1.0rem", textDecoration: 'none' }}> MNM Solutions Careers</a>
                </li>
                <li style={{ marginBottom: '10px' }}>
                  <a href="#Awards" className="text-white" style={{ fontFamily: "Arial, sans-serif", fontSize: "1.0rem", textDecoration: 'none' }}>MNM Solutions Awards</a>
                </li>
              </ul>
              <img src="assets/img/mnm-g.png" width={250} />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright text-center py-3">
          © Copyright <strong><span>mnmsolutions.in</span></strong> All Rights Reserved
        </div>
      </div>
    </footer>
  );
}

export default Fotter;
